import { FC, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { NavbarProps } from "../../types/navbar";
import AuthContext from "../../contexts/authContext";

const Navigation: FC = () => {
    const location = useLocation();
    const { navbar } = useContext(AuthContext);
    const submenu = navbar.filter((item: NavbarProps) => location.pathname.includes(item.url));

    return (
        <div className="controls-stats-nav">
            {submenu.map((item: NavbarProps) => (
               item.children.map((link, index) => (
                <NavLink
                    key={index}
                    to={link.url}
                    className={ `${location.pathname === link.url ? "controls-stats-nav-item-active" : "controls-stats-nav-item"}` }
                    >
                    {link.title}
                </NavLink>
               ))
            ))}
        </div>
    );
};

export default Navigation;
