import { AuthActions, AuthActionsTypes, IInitialState } from "../../types/auth";

const initialState: IInitialState = {
    isAuth: false,
    authData: [],
    permissionsByFeatures: {
        statistics: 0,
        detailed_stats: 0,
        can_export_stats: 0,
        available_monthly_report_stats: 0,
        s3_integration: 0,
        max_detailed_stat_export_period: 0,
        stats_period: 0
    },
    token: {
        access: null,
        refresh: null
    },
    loading: false,
    error: null,
};

export const authReducer = (state = initialState, action: AuthActions): IInitialState => {
    switch (action.type) {
        case AuthActionsTypes.GET_AUTH:
            return {
                ...state,
                loading: true,
                error: null,
                authData: [],
            }
        case AuthActionsTypes.GET_AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                authData: action.payload,
            }
        case AuthActionsTypes.GET_AUTH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                authData: [],
            }
        case AuthActionsTypes.GET_AUTH_TOKEN:
            return {
                ...state,
                isAuth: false
            }
        case AuthActionsTypes.GET_AUTH_TOKEN_SUCCESS:
            return {
                ...state,
                isAuth: true,
                token: action.payload,
            }
        case AuthActionsTypes.GET_AUTH_TOKEN_FAILURE:
            return {
                ...state,
                isAuth: false,
            }
        case AuthActionsTypes.AUTH_PERMISSIONS_BY_FEATURES:
            return {
                ...state,
                permissionsByFeatures: action.payload,
            }
        default:
            return state
    };
};
