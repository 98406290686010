import { FC, useContext } from "react";
// import { useLocation } from "react-router-dom";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useCheckBreakpoint } from "../../hooks/useCheckBreakpoint";
import NavbarContext from "../../contexts/navbarContext";
import './Header.scss';

const Header: FC = () => {
    // const location = useLocation();
    const isAuthData = useTypedSelector(state => state.auth.authData);
    const { isShowNavbar, setIsShowNavbar } = useContext(NavbarContext);

    // const pathname = location.pathname.replace('/cabinet/', '').slice(0, -1)

    // const title = () => {
    //     if (!location.pathname.includes("detailed") && !location.pathname.includes("data-export")) {
    //         return "Stats"
    //     } else if (location.pathname.includes("detailed")) {
    //         return "Detailed reports"
    //     } else if (location.pathname.includes("data-export")) {
    //         return "Data Export"
    //     }
    // };

    // const ipInfo = (title: string, ip: string) => {
    //     return (
    //         <div>
    //             <span className="header-info-title">{`${title}:`}</span>
    //             <span className="header-info-ip">{ip}</span>
    //         </div>
    //     )
    // };

    return (
        <header>
            {useCheckBreakpoint(768) ?
                <div className="header-mobile">
                    <a href={process.env.REACT_APP_BASE_URL} className="header-controls-logo">
                        <div className="header-controls-logo-icon"></div>
                    </a>

                    <div className="header-mobile-controls">
                        {isAuthData.is_authenticated && <a href={`${process.env.REACT_APP_BASE_URL}/auth/logout`} className="header-controls-logout"> </a>}
                        <button
                            type="button"
                            className={`header-controls-navbar-${isShowNavbar ? "close" : "open"}`}
                            onClick={() => setIsShowNavbar(!isShowNavbar)}
                        />
                    </div>
                </div> :
                <div className="header">
                    {/* <h1 className="header-title">{title()}</h1> */}
                    <a href={process.env.REACT_APP_BASE_URL} className="header-controls-logo">
                        <div className="header-controls-logo-icon"></div>
                    </a>
                    {/* <div className="header-info">
                        {ipInfo('Ваш IP-адрес', '94.50.147.65')}
                        {ipInfo('Наш DNS-сервер', '193.58.251.251')}
                    </div> */}
                    <div className="header-controls">
                        {isAuthData.permissions.is_staff && <a href={`${process.env.REACT_APP_BASE_URL}/you-were-never-here/`} className="header-controls-admin">Admin interface</a>}
                        {isAuthData.is_authenticated && <a href={`${process.env.REACT_APP_BASE_URL}/auth/logout`} className="header-controls-logout">Выйти</a>}
                    </div>
                </div>
            }
        </header>
    );
};

export default Header;
