import { FC, useEffect, useState } from 'react';
import axios from 'axios';
import Endpoints from '../../../api/endpoints';
import { useDispatch } from 'react-redux';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { StatsDomainsActionsTypes, IDomainItem } from "../../../types/statsDomains";
import { ICategotiesItemStats } from '../../../types/statsCategories';
import { renderDeviceName } from '../devices/DeviceItemName';
import { useCheckBreakpoint } from '../../../hooks/useCheckBreakpoint';
import ContentBlock from '../../UI/content-block/ContentBlock';
import InfoBlockAccordion from '../../UI/info-block-accordion/InfoBlockAccordion';
import TopFactsInfo from './TopFactsInfo';
import ProgressLoader from '../../UI/progress-loader/ProgressLoader';
import './TopFacts.scss';

const TopFacts: FC = () => {
    const dispatch = useDispatch();
    const { statsDomainsTopBlockedRequest } = useActions();
    const { STATS } = Endpoints;
    const token = useTypedSelector(state => state.auth.token);
    const period = useTypedSelector(state => state.period.period);
    const profiles = useTypedSelector(state => state.profiles.profiles);
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);
    const domainsData = useTypedSelector(state => state.statsDomains.statsDomainsData)
        .filter((item) => item.blocks !== 0);
    const [domainsTopBlockedDataJson, setDomainsTopBlockedDataJson] = useState<string>("");
    const [domainsTopBlockedDataResult, setDomainsTopBlockedDataResult] = useState<IDomainItem[]>([]);
    const domainsTopBlockedLoading = useTypedSelector(state => state.statsDomains.statsDomainsTopBlockedLoading);
    const domainsTopBlockedError = useTypedSelector(state => state.statsDomains.statsDomainsTopBlockedError);
    const devicesData = useTypedSelector(state => state.statsDevices.statsDevicesData);
    const deviceList = useTypedSelector(state => state.statsDevices.deviceList);
    const devicesLoading = useTypedSelector(state => state.statsDevices.loading);
    const devicesError = useTypedSelector(state => state.statsDevices.error);
    const categoriesData = useTypedSelector(state => state.statsCategories.statsCategoriesData)
        .sort((a,b) => b.blocks - a.blocks)
        .filter((item) => item.blocks !== 0)
        .slice(0, 10);
    const categoriesLoading = useTypedSelector(state => state.statsCategories.loading);
    const categoriesError = useTypedSelector(state => state.statsCategories.error);

    //get domains top blocked

    let recursionCount = 0;

    const getDomainsTopBlocked = () => {
        dispatch({
            type: StatsDomainsActionsTypes.GET_STATS_DOMAINS_TOP_BLOCKED
        });

        axios.post(`${process.env.REACT_APP_STATS_URL}${STATS.DOMAINS}`, { ...requestData, order_by: "-blocks", limit: 10 }, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then(response => {
            if (response.data.status.includes('complete')) {
                setDomainsTopBlockedDataJson(response.data.message);

                return dispatch({
                    type: StatsDomainsActionsTypes.GET_STATS_DOMAINS_TOP_BLOCKED_SUCCESS,
                    payload: response.data.message
                });
            } else {
                return setTimeout(() => {
                    recursionCount++

                    //exit from recursion with a long query
                    if (recursionCount >= 10) {
                        return dispatch({
                            type: StatsDomainsActionsTypes.GET_STATS_DOMAINS_TOP_BLOCKED_FAILURE,
                            payload: 'Упс! Что-то пошло не так... Повторите попытку позже.'
                        });
                    } else {
                        return getDomainsTopBlocked();
                    }
                }, 3000);
            }
        })
        .catch(() => {
            dispatch({
                type: StatsDomainsActionsTypes.GET_STATS_DOMAINS_TOP_BLOCKED_FAILURE,
                payload: 'Упс! Что-то пошло не так... Повторите попытку позже.'
            });
        });
    };

    useEffect(() => {
        if (period !== 'Range' && requestData.profile_ids.length !== 0) {
            getDomainsTopBlocked();
        }

        if (profiles.length !== 0) {
            statsDomainsTopBlockedRequest(getDomainsTopBlocked);
        }
    }, [requestData]);

    //get domains top blocked to json
    useEffect(() => {
        axios.get(domainsTopBlockedDataJson, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then((response) => {
            setDomainsTopBlockedDataResult(Array.from(response.data));
        })
        .catch((error) => {
            console.log('error');
        })
    }, [domainsTopBlockedDataJson]);

    return (
        <ContentBlock
            title={"Обзор заблокированных действий"}
            padding={useCheckBreakpoint(576) ? "16px 16px 0" : "0 24px"}
            content={
                <>
                    <div className="stats-top-facts">
                        <div className="stats-top-facts-domains">
                            <div
                                style={{
                                    opacity: domainsTopBlockedLoading || domainsTopBlockedError ? 0.2 : 1,
                                    pointerEvents: domainsTopBlockedLoading || domainsTopBlockedError ? 'none' : 'auto'
                                }}
                            >
                                <h3 className="stats-top-facts-domains-list-title">ТОП ЗАБЛОКИРОВАННЫХ ДОМЕНОВ</h3>
                                {domainsTopBlockedLoading &&
                                    <ProgressLoader />
                                }
                                <ul className="stats-top-facts-domains-list">
                                    {domainsTopBlockedDataResult.map((item, index) => (
                                        item.domain &&
                                        <li
                                            key={index}
                                            className="stats-top-facts-domains-list-item"
                                        >
                                            <p className="stats-top-facts-domains-list-item-name" title={item.domain}>{item.domain}</p>
                                            <p className="stats-top-facts-domains-list-item-requests">{item.blocks}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {domainsTopBlockedError &&
                                <div className="stats-top-facts-domains-error">
                                    <p>{domainsTopBlockedError}</p>
                                </div>
                            }

                            {!domainsTopBlockedError && domainsData.length === 0 &&
                                <div className="stats-top-facts-domains-error">
                                    <p>У вас еще нет статистики</p>
                                </div>
                            }
                        </div>

                        {
                            requestData.ipv4_addresses.length === 0 &&
                            requestData.ipv6_networks.length === 0 &&
                            requestData.tokens.length === 0 ?
                            <div className="stats-top-facts-devices">
                                <div
                                    style={{
                                        opacity: devicesLoading || devicesError ? 0.2 : 1,
                                        pointerEvents: devicesLoading || devicesError ? 'none' : 'auto'
                                    }}
                                >
                                    <h3 className="stats-top-facts-devices-list-title">ТОП ЗАБЛОКИРОВАННЫХ УСТРОЙСТВ</h3>
                                    {devicesLoading &&
                                        <ProgressLoader />
                                    }
                                    <ul className="stats-top-facts-devices-list">
                                        {devicesData.map((item, index) => (
                                            item.requests &&
                                            <li
                                                key={index}
                                                className="stats-top-facts-devices-list-item"
                                            >

                                                <p className="stats-top-facts-devices-list-item-name">
                                                    {renderDeviceName(deviceList, item)}
                                                </p>
                                                <p className="stats-top-facts-devices-list-item-requests">{item.blocks}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {devicesError &&
                                    <div className="stats-top-facts-devices-error">
                                        <p>Упс! Что-то пошло не так... Повторите попытку позже.</p>
                                    </div>
                                }

                                {!devicesError && devicesData.length === 0 &&
                                    <div className="stats-top-facts-devices-error">
                                        <p>У вас еще нет статистики</p>
                                    </div>
                                }
                            </div> : null
                        }

                        <div className="stats-top-facts-categories">
                            <div
                                style={{
                                    opacity: categoriesLoading || categoriesError ? 0.2 : 1,
                                    pointerEvents: categoriesLoading || categoriesError ? 'none' : 'auto'
                                }}
                            >
                                <h3 className="stats-top-facts-categories-list-title">ТОП ЗАБЛОКИРОВАННЫХ КАТЕГОРИЙ</h3>
                                {categoriesLoading &&
                                    <ProgressLoader />
                                }
                                <ul className="stats-top-facts-categories-list">
                                    {categoriesData.map((item: ICategotiesItemStats, index: number) => (
                                        item.cat &&
                                        <li
                                            key={index}
                                            className="stats-top-facts-categories-list-item"
                                        >
                                            <p className="stats-top-facts-categories-list-item-name">
                                                {item.cat && item.cat.title}
                                            </p>
                                            <p className="stats-top-facts-categories-list-item-requests">
                                                {item && item.blocks}
                                            </p>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {categoriesError &&
                                <div className="stats-top-facts-categories-error">
                                    <p>Упс! Что-то пошло не так... Повторите попытку позже.</p>
                                </div>
                            }

                            {!categoriesError && categoriesData.length === 0 &&
                                <div className="stats-top-facts-categories-error">
                                    <p>У вас еще нет статистики</p>
                                </div>
                            }
                        </div>
                    </div>
                    <InfoBlockAccordion
                        id="activity_overview"
                        title="10 самых заблокированных доменов, устройств с наибольшим количеством попыток доступа и заблокированных категорий по количеству запросов."
                        margin="24px 0 0"
                        is_content={true}
                        content={<TopFactsInfo />}
                    />
                </>
            }
        />
    )
};

export default TopFacts;
