import { StatsHighlightsActionsTypes } from "../../types/statsHighlights";
import { StatsActivityActionsTypes } from "../../types/statsActivity";
import { StatsCategoriesActionsTypes } from "../../types/statsCategories";
import { StatsDomainsActionsTypes } from "../../types/statsDomains";
import { StatsDevicesActionsTypes } from "../../types/statsDevices";
import { StatsDetailedActionsTypes } from "../../types/statsDetailed";

export const statsHighlightsRequest = (request: any) => (
    {
        type: StatsHighlightsActionsTypes.STATS_HIGHLIGHTS_REQUEST,
        payload: request
    }
);

export const statsActivityRequest = (request: any) => (
    {
        type: StatsActivityActionsTypes.STATS_ACTIVITY_REQUEST,
        payload: request
    }
);

export const statsCategoriesRequest = (request: any) => (
    {
        type: StatsCategoriesActionsTypes.STATS_CATEGORIES_REQUEST,
        payload: request
    }
);

export const statsDomainsRequest = (request: any) => (
    {
        type: StatsDomainsActionsTypes.STATS_DOMAINS_REQUEST,
        payload: request
    }
);

export const statsDomainsTopBlockedRequest = (request: any) => (
    {
        type: StatsDomainsActionsTypes.STATS_DOMAINS_TOP_BLOCKED_REQUEST,
        payload: request
    }
);

export const statsDevicesRequest = (request: any) => (
    {
        type: StatsDevicesActionsTypes.STATS_DEVICES_REQUEST,
        payload: request
    }
);

export const statsDetailedRequest = (request: any) => (
    {
        type: StatsDetailedActionsTypes.STATS_DETAILED_REQUEST,
        payload: request
    }
);
