export enum StatsDomainsActionsTypes {
    GET_STATS_DOMAINS = "GET_STATS_DOMAINS",
    GET_STATS_DOMAINS_SUCCESS = "GET_STATS_DOMAINS_SUCCESS",
    GET_STATS_DOMAINS_FAILURE = "GET_STATS_DOMAINS_FAILURE",
    GET_STATS_DOMAIN_DEVICES = "GET_STATS_DOMAIN_DEVICES",
    GET_STATS_DOMAIN_DEVICES_SUCCESS = "GET_STATS_DOMAIN_DEVICES_SUCCESS",
    GET_STATS_DOMAIN_DEVICES_FAILURE = "GET_STATS_DOMAIN_DEVICES_FAILURE",
    GET_STATS_DOMAINS_TOP_BLOCKED = "GET_STATS_DOMAINS_TOP_BLOCKED",
    GET_STATS_DOMAINS_TOP_BLOCKED_SUCCESS = "GET_STATS_DOMAINS_TOP_BLOCKED_SUCCESS",
    GET_STATS_DOMAINS_TOP_BLOCKED_FAILURE = "GET_STATS_DOMAINS_TOP_BLOCKED_FAILURE",
    STATS_DOMAINS_DATA = "STATS_DOMAINS_DATA",
    STATS_DOMAINS_REQUEST = "STATS_DOMAINS_REQUEST",
    STATS_DOMAINS_TOP_BLOCKED_REQUEST = "STATS_DOMAINS_TOP_BLOCKED_REQUEST",
    STATS_DOMAINS_DATA_RESULT = "STATS_DOMAINS_DATA_RESULT",
};

export interface IDomainItem {
    domain: string,
    requests: number
    blocks: number,
    cat_ids: string[],
};

export interface IInitialState {
    statsDomains: string,
    statsDomainsTopBlocked: string,
    statsDomainsTopBlockedLoading: boolean,
    statsDomainsTopBlockedError: null | string,
    statsDomainsData: IDomainItem[],
    loading: boolean,
    error: null | string,
    getDomainDevices: any[],
    getDevicesLoading: boolean,
    getDevicesError: null | string,
    request: any,
    requestTopBlocked: any,
    dataResult: any,
};

interface IGetStatsDomains {
    type: StatsDomainsActionsTypes.GET_STATS_DOMAINS,
};

interface IGetStatsDomainsSuccess {
    type: StatsDomainsActionsTypes.GET_STATS_DOMAINS_SUCCESS,
    payload: string
};

interface IGetStatsDomainsFailure {
    type: StatsDomainsActionsTypes.GET_STATS_DOMAINS_FAILURE,
    payload: string
};

interface IGetStatsDomainDevices {
    type: StatsDomainsActionsTypes.GET_STATS_DOMAIN_DEVICES,
};

interface IGetStatsDomainDevicesSuccess {
    type: StatsDomainsActionsTypes.GET_STATS_DOMAIN_DEVICES_SUCCESS,
    payload: any[]
};

interface IGetStatsDomainDevicesFailure {
    type: StatsDomainsActionsTypes.GET_STATS_DOMAIN_DEVICES_FAILURE,
    payload: string
};

interface IGetStatsDomainsTopBlocked {
    type: StatsDomainsActionsTypes.GET_STATS_DOMAINS_TOP_BLOCKED,
};

interface IGetStatsDomainsTopBlockedSuccess {
    type: StatsDomainsActionsTypes.GET_STATS_DOMAINS_TOP_BLOCKED_SUCCESS,
    payload: string
};

interface IGetStatsDomainsTopBlockedFailure {
    type: StatsDomainsActionsTypes.GET_STATS_DOMAINS_TOP_BLOCKED_FAILURE,
    payload: string
};

interface IStatsDomainsData {
    type: StatsDomainsActionsTypes.STATS_DOMAINS_DATA,
    payload: IDomainItem[]
};

interface IStatsDomainsRequest {
    type: StatsDomainsActionsTypes.STATS_DOMAINS_REQUEST,
    payload: any
};

interface IStatsDomainsTopBlockedRequest {
    type: StatsDomainsActionsTypes.STATS_DOMAINS_TOP_BLOCKED_REQUEST,
    payload: any
};

interface IStatsDomainsDataResult {
    type: StatsDomainsActionsTypes.STATS_DOMAINS_DATA_RESULT,
    payload: any
};

export type StatsDomainsActions =
    IGetStatsDomains |
    IGetStatsDomainsSuccess |
    IGetStatsDomainsFailure |
    IGetStatsDomainDevices |
    IGetStatsDomainDevicesSuccess |
    IGetStatsDomainDevicesFailure |
    IGetStatsDomainsTopBlocked |
    IGetStatsDomainsTopBlockedSuccess |
    IGetStatsDomainsTopBlockedFailure |
    IStatsDomainsData |
    IStatsDomainsRequest |
    IStatsDomainsTopBlockedRequest |
    IStatsDomainsDataResult;
