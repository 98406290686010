export enum NavbarItemsActionsTypes {
    GET_NAVBAR_ITEMS = "GET_NAVBAR_ITEMS",
    GET_NAVBAR_ITEMS_SUCCESS = "GET_NAVBAR_ITEMS_SUCCESS",
    GET_NAVBAR_ITEMS_FAILURE = "GET_NAVBAR_ITEMS_FAILURE"
};

export interface IInitialState {
    navbarItems: any,
    loading: boolean,
    error: null | string,
};

interface IGetNavbarItems {
    type: NavbarItemsActionsTypes.GET_NAVBAR_ITEMS,
};

interface IGetNavbarItemsSuccess {
    type: NavbarItemsActionsTypes.GET_NAVBAR_ITEMS_SUCCESS,
    payload: any
};

interface IGetNavbarItemsFailure {
    type: NavbarItemsActionsTypes.GET_NAVBAR_ITEMS_FAILURE,
    payload: string
};

export type NavbarItemsActions =
    IGetNavbarItems |
    IGetNavbarItemsSuccess |
    IGetNavbarItemsFailure;
