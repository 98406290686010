export enum AuthActionsTypes {
    GET_AUTH = "GET_AUTH",
    GET_AUTH_SUCCESS = "GET_AUTH_SUCCESS",
    GET_AUTH_FAILURE = "GET_AUTH_FAILURE",
    GET_AUTH_TOKEN = "GET_AUTH_TOKEN",
    GET_AUTH_TOKEN_SUCCESS = "GET_AUTH_TOKEN_SUCCESS",
    GET_AUTH_TOKEN_FAILURE = "GET_AUTH_TOKEN_FAILURE",
    AUTH_PERMISSIONS_BY_FEATURES = "AUTH_PERMISSIONS_BY_FEATURES"
};

interface IAuthPermissions {
    is_staff: boolean,
    is_superuser: boolean
}

export interface IPermissionFeatures {
    statistics: number,
    detailed_stats: number,
    can_export_stats: number,
    available_monthly_report_stats: number,
    s3_integration: number,
    max_detailed_stat_export_period: number,
    stats_period: number
}

export interface IAuthData {
    is_authenticated: boolean,
    username: string,
    user_id: number | string,
    plan: string,
    date_end: string,
    language: string,
    permissions: IAuthPermissions
};

export interface IAuthResponseJwt {
    access: string|null
    refresh: string|null
};

export interface IInitialState {
    isAuth: boolean
    authData: any,
    token: IAuthResponseJwt,
    permissionsByFeatures: IPermissionFeatures,
    loading: boolean,
    error: null | string,
};

interface IGetAuth {
    type: AuthActionsTypes.GET_AUTH,
};

interface IGetAuthSuccess {
    type: AuthActionsTypes.GET_AUTH_SUCCESS,
    payload: IAuthData
};

interface IGetAuthFailure {
    type: AuthActionsTypes.GET_AUTH_FAILURE,
    payload: string
};

interface IGetAuthToken {
    type: AuthActionsTypes.GET_AUTH_TOKEN,
};

interface IGetAuthTokenSuccess {
    type: AuthActionsTypes.GET_AUTH_TOKEN_SUCCESS,
    payload: IAuthResponseJwt
};

interface IGetAuthTokenFailure {
    type: AuthActionsTypes.GET_AUTH_TOKEN_FAILURE,
    payload: string
};

interface IAuthPermissionsByFeatures {
    type: AuthActionsTypes.AUTH_PERMISSIONS_BY_FEATURES,
    payload: IPermissionFeatures
};

export type AuthActions =
    IGetAuth |
    IGetAuthSuccess |
    IGetAuthFailure |
    IGetAuthToken |
    IGetAuthTokenSuccess |
    IGetAuthTokenFailure |
    IAuthPermissionsByFeatures;
